(function($){

    var months                  = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var currentMonthFirstDay    = null;
    var container               = null;
    var disabledDays            = [];
    var daysOfWeek              = [true, true, true, true, true, true, true];
    var selectedDays            = [];
    var callbacks               = {};
    var selectOne               = false;
    var minDate                 = false;
    var maxDate                 = false;
    var allowDays               = [];
    var lockedDays              = [];
    var customDateClasses       = {};
    var cell_content            = '';

    $.fn.simpleCalendar = function(options){

        container = this;
        $(container).addClass('simple_calendar');

        //Handle Options
        if(typeof options == 'undefined'){
            options = {};
        }
        if(options.startView == undefined){
            options.startView = new Date();
        }
        if(options.dayNames == undefined){
            options.dayNames = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
        }
        if(options.daysOfWeek){ daysOfWeek = options.daysOfWeek; }
        if(options.disabledDays){ disabledDays = options.disabledDays; }
        if(options.selectedDays){ selectedDays = options.selectedDays; }
        if(options.callbacks){ callbacks = options.callbacks; }
        if(options.selectOne){ selectOne = options.selectOne; }
        if(options.minDate){ minDate = options.minDate; }
        if(options.maxDate){ maxDate = options.maxDate; }
        if(options.allowDays){ allowDays = options.allowDays; }
        if(options.cell_content){ cell_content = options.cell_content; }

        //Add Month Switcher 
        addCalendarHeader(options);

        //Build Basic Calendar Table
        addCalendarTable(options);

        //Populate Calendar with Current Month Days
        var month               = options.startView.getMonth();
        var year                = options.startView.getFullYear();
        currentMonthFirstDay    = new Date(year, month, 1);
        populateCurrentMonthDays();

        //Define Functions
        this.setDays = function(daysArray){
            selectedDays = daysArray;
            populateCurrentMonthDays();
        }
        this.removeDay = function(dayToRemove){
            if(selectedDays.indexOf(dayToRemove) != -1){
                selectedDays.splice(selectedDays.indexOf(dayToRemove), 1);
                populateCurrentMonthDays();
            }
            return selectedDays;
        }
        this.getDaysSelected = function(){
            return selectedDays;
        }
        this.canSelectOne = function(canSelectOne){
            selectOne = canSelectOne;
        }
        this.lockDays = function(){
            $(container).find('.sc_selected').addClass('sc_locked');
            lockedDays = selectedDays;
        }
        this.addClassToDates = function ( classes, dates ) {
            // classes can be space separated or array of strings
            // dates can be space separated or array of Y-m-d
            if ( typeof classes == 'string' ) { classes = classes.split(' '); }
            if ( typeof dates == 'string' ) { dates = dates.split(' '); }
            $.each(dates, function ( index, date ){
                if ( !customDateClasses.hasOwnProperty( date ) ) {
                    customDateClasses[date] = [];
                }
                classes = $.grep( classes, function ( className ) {
                    return ( customDateClasses[date].indexOf( className ) == -1 );
                });
                $.merge( customDateClasses[date], classes );

                var dateSpan = $('span[data-date="'+date+'"]', container);
                if ( dateSpan.length ) {
                    dateSpan.closest('td').addClass( classes.join(' ') );
                }
            });
        }
        this.removeClassFromDates = function ( classes, dates ) {
            if ( typeof classes == 'string' ) { classes = classes.split(' '); }
            if ( typeof dates == 'string' ) { dates = dates.split(' '); }
            $.each(dates, function ( index, date ){
                if ( customDateClasses.hasOwnProperty( date ) ) {
                    customDateClasses[date] = $.grep( customDateClasses[date], function ( className ) {
                        return ( classes.indexOf( className ) == -1 );
                    });
                }
                var dateSpan = $('span[data-date="'+date+'"]', container);
                if ( dateSpan.length ) {
                    dateSpan.closest('td').removeClass( classes.join(' ') );
                }
            });
        }
        this.setDisabledDays = function( disabledDaysParam ){
            disabledDays = disabledDaysParam;
            selectedDays.filter( date => disabledDays.includes( date ) ).forEach( date => deselectDate( date ) );
            populateCurrentMonthDays();
        }
        this.selectDate = selectDate;
        this.deselectDate = deselectDate;
        this.toggleDate = toggleDate;

        this.getMinDate = function () {
            return minDate;
        }
        this.getMaxDate = function () {
            return maxDate;
        }

        return this;

    };

    function addCalendarHeader(options){
        var header    = '<div class="sc_header"><i class="fa fa-caret-left sc_prev"></i><span class="sc_current_month"></span><i class="fa fa-caret-right sc_next"></i></div>';
        $(container).append(header);
    }

    function addCalendarTable(options){
        var calendarTable = '<table class="sc_table"><thead>';
        options.dayNames.map(function(thisDay){
            calendarTable += '<th>'+ thisDay +'</th>';
        });
        calendarTable += '</thead><tbody>';
        for( var week = 1; week <= 6; week++ ){
            calendarTable += '<tr data-week="'+ week +'">';
            for( var day = 1; day <= 7; day++ ){
                calendarTable += '<td data-day="'+ day +'"></td>';
            }
            calendarTable += '</tr>';
        }
        calendarTable += '</tbody></table>';
        $(container).append(calendarTable);
    }

    function populateCurrentMonthDays(){

        var lastDay     = new Date(currentMonthFirstDay.getFullYear(), currentMonthFirstDay.getMonth() + 1, 0);
        
        var currentDay  = new Date(currentMonthFirstDay.getTime());
        var firstDOW    = currentDay.getDay();
        $(container).find('tbody tr td').html('');
        $(container).find('tbody tr td').removeClass();
        var $currentCell    = $(container).find('tbody tr').first().find('td').eq(firstDOW);

        if ( minDate && ( minDate.getTime() > currentDay.getTime() ) ) {
            $('.sc_prev', container).addClass('disabled');
        } else {
            $('.sc_prev', container).removeClass('disabled');
        }

        if ( maxDate && ( maxDate.getTime() < lastDay.getTime() ) ) {
            $('.sc_next', container).addClass('disabled');
        } else {
            $('.sc_next', container).removeClass('disabled');
        }

        var today = new Date();
        while(currentDay <= lastDay){
            var day = currentDay.getDate();
            if(day < 10){
                day = '0' + day;
            }
            var dayString = dateToStringFormat(currentDay);
            $currentCell.append(`<span class="sc_date" data-time="${ currentDay.getTime() }" data-date="${ dayString }">${ day }</span><div>${ cell_content }</div>`);
            if ( customDateClasses.hasOwnProperty( dayString ) ) {
                $currentCell.addClass( customDateClasses[dayString].join(' ') );
            }
            
            //Is this day available for selection?
            if(daysOfWeek[currentDay.getDay()] && disabledDays.indexOf(dayString) == -1){
                if( ( !minDate || currentDay.getTime() >= minDate.getTime() ) && ( !maxDate || currentDay.getTime() <= maxDate.getTime() ) ){
                    $currentCell.addClass('sc_available');
                }
                else if(allowDays && isAllowedDay(currentDay)){
                    $currentCell.addClass('sc_available');
                }
            }
            else if(disabledDays.indexOf(dayString) != -1){
                $currentCell.addClass('sc_unavailable');
            }

            //Set already selected days
            if( selectedDays.indexOf(dayString) != -1 ){
                $currentCell.addClass('sc_selected');
            }

            //Set locked days
            if( lockedDays.indexOf(dayString) != -1 ){
                $currentCell.addClass('sc_locked');
            }

            if(dayString == dateToStringFormat(today)){
                $currentCell.addClass('sc_today');
            } else if ( today.getTime() > currentDay.getTime() ) {
                $currentCell.addClass('sc_past');
            } else {
                $currentCell.addClass('sc_future');
            }
            
            //Set for next loop
            currentDay.setDate(currentDay.getDate() + 1);
            if($currentCell.next().length){
                $currentCell = $currentCell.next();
            }
            else{
                $currentCell = $currentCell.closest('tr').next().find('td').first();
            }
        }
        if($(container).find('tbody tr').last().find('.sc_date').length == 0){
            $(container).find('tbody tr').last().hide();
        }
        else{
            $(container).find('tbody tr').last().show();
        }

        var monthName = months[ currentMonthFirstDay.getMonth()];
        var year      = currentMonthFirstDay.getFullYear();
        $('.sc_current_month').html(monthName + ' ' + year);

        if(callbacks.changeMonth){
            callbacks.changeMonth(currentMonthFirstDay);
        }

    }

    function isAllowedDay(dayToCheck) {
        var isAllowed = false;
        allowDays.forEach(function(allowedDay){
            if(dayToCheck.getFullYear() === allowedDay.getFullYear() &&
            dayToCheck.getMonth() === allowedDay.getMonth() &&
            dayToCheck.getDate() === allowedDay.getDate()){
                isAllowed = true;
            }
        });
        return isAllowed;
    }

    function selectDate ( date ) {
        let $date_span = $( `.sc_table td span[data-date=${ date }]`);
        if ( !$date_span.length ) { return; }
        let $td = $date_span.closest( 'td' );
        if ( !$td.length || !$td.hasClass( 'sc_available' ) || $td.hasClass( 'locked' ) || selectedDays.some( x => x == date ) ) { return; }
        if ( selectOne ) {
            selectedDays = [];
            $( '.sc_selected' ).removeClass( 'sc_selected' );
        }
        $td.addClass('sc_selected');
        selectedDays.push( date );
        if(callbacks.dateSelected){
            callbacks.dateSelected(this, selectedDays);
        }
    }

    function deselectDate ( date ) {
        let $date_span = $( `.sc_table td span[data-date=${ date }]`);
        if ( !$date_span.length ) { return; }
        let $td = $date_span.closest( 'td' );
        if ( !$td.length || !selectedDays.some( x => x == date ) ) { return; }
        $td.removeClass('sc_selected');
        selectedDays.splice( selectedDays.indexOf( date ), 1 );
        if(callbacks.dateSelected){
            callbacks.dateSelected(this, selectedDays);
        }
    }

    function toggleDate ( date ) {
        let $date_span = $( `.sc_table td span[data-date=${ date }]`);
        if ( !$date_span.length ) { return; }
        let $td = $date_span.closest( 'td' );
        if ( !$td.length ) { return; }
        if ( $td.hasClass( 'sc_selected' ) ) {
            deselectDate( date );
        } else {
            selectDate( date );
        }
    }

    //HELPER FUNCTIONS

    //Convert Date Object into Y-m-d string
    function dateToStringFormat(dateIn){
        return dateIn.getFullYear() + '-' + ("0" + (dateIn.getMonth() + 1)).slice(-2) + '-' + ("0" + dateIn.getDate()).slice(-2);
    }

    //LISTENERS
    $(document).on('click', '.sc_header .sc_prev', function(){
        currentMonthFirstDay = new Date(currentMonthFirstDay.getFullYear(), currentMonthFirstDay.getMonth() - 1, 1);
        populateCurrentMonthDays();
    });
    $(document).on('click', '.sc_header .sc_next', function(){
        currentMonthFirstDay = new Date(currentMonthFirstDay.getFullYear(), currentMonthFirstDay.getMonth() + 1, 1);
        populateCurrentMonthDays();
    });


    $(document).on('click', 'td.sc_available', function(){
        var userAgent = window.navigator.userAgent;
        if ( userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) ) {
            // bail if is iOS device; hover/click events handled separately in template-date-select.js
            return;
        }

        if( $( this ).hasClass( 'sc_locked' ) ){
            return;
        }
        toggleDate( $(this).find('.sc_date').data().date );
        // if(callbacks.dateSelected){
        //     callbacks.dateSelected(this, selectedDays);
        // }
    });


    $(document).on('click', 'td.sc_unavailable', function(){
        if(callbacks.unavailableClicked){
            callbacks.unavailableClicked(this, selectedDays);
        }
    });

})(jQuery);