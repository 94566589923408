/*-------------------------------------------------------------------*\

	JQuery extension for easy load screens

    Usage:
    $('selector').loading_screen( show, callback );

    Params:
        show:
            Boolean
            Default = true
            Whether loading screen is coming in or out
        callback:
            Function
            Default = null
            Function to execute after transition has completed

    Styling handled in SCSS

\*-------------------------------------------------------------------*/


(function ( $, root ) {

    var $loading_screen = false;
    var transition_duration = 0;

    function init () {
        var $icon = $('<img/>').attr( 'src', ThemeJS.Variables.loading_svg ).addClass('loading-icon fa-spin');
        var $icon_wrapper = $('<div/>').addClass('loading-icon-wrapper').append( $icon );
        $loading_screen = $('<div/>').addClass('simplistics-loading-screen').append( $icon_wrapper );
        $('body').append( $loading_screen );
        var transition_duration_string = $loading_screen.css('transition-duration'); // js reads in seconds (will convert from ms)
        transition_duration = parseInt( 1000 * parseFloat( transition_duration_string ) ); // convert to int in ms
        $loading_screen.remove();

        $.fn.loading_screen = function ( show, callback ) {
            show = ( typeof show == 'undefined' || Boolean( show ) );
            if ( show ) {
                if ( $( '.simplistics-loading-screen', this ).length ) { return; }
                if ( $(this).css('position') == 'static' ) { $(this).css( 'position', 'relative' ); }
                var $local_load_screen = $loading_screen.clone();
                $(this).append( $local_load_screen );
                setTimeout( function () {
                    $local_load_screen.addClass('loading');
                }, 0 );
                if ( typeof callback == 'function' ) {
                    setTimeout( callback, transition_duration );
                }
            } else {
                var $local_load_screen = $( '.simplistics-loading-screen', this );
                if ( !$local_load_screen.length ) { return; }
                $local_load_screen.removeClass('loading');
                setTimeout(function(){
                    $local_load_screen.remove();
                    if ( typeof callback == 'function' ) {
                        callback();
                    }
                }, transition_duration );
            }
        }

    }

    $( function () {
        init();
    });

})( jQuery, this );