( function ( $, root ) {

    let $mobile_menu;
    let $mobile_menu_toggle;

    function toggle_menu () {
        let closing = $mobile_menu.is(':visible');
        $mobile_menu_toggle.toggleClass( 'fa-bars', closing ).toggleClass( 'fa-times', !closing );
        $mobile_menu.slideToggle();
    }

    $( document ).ready( function () {
        $mobile_menu = $( '.mobile-menu' );
        $mobile_menu_toggle = $( '.mobile-menu-toggle' );
        if ( !$mobile_menu.length || !$mobile_menu_toggle.length ) {
            return;
        }
        $mobile_menu_toggle.click( toggle_menu );
    });

})( jQuery, this );