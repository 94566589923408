class Number_Input {

    constructor ( $element ) {
        if ( !$element.hasClass( 'number-input' ) ) {
            console.error( 'Invalid Number Input' );
            return;
        }
        this.$element = $element;
        this.$minus = $element.find( '.decrement' );
        this.$plus = $element.find( '.increment' );
        this.$input = $element.find( 'input' );

        let min = parseInt( this.$input.attr( 'min' ) );
        this.min = isNaN( min ) ? false : min;
        let min_error = this.$input.attr( 'data-min_error' );
        this.min_error = min_error ? min_error : `The minimum value is {min}`;

        let max = parseInt( this.$input.attr( 'max' ) );
        this.max = isNaN( max ) ? false : max;
        let max_error = this.$input.attr( 'data-max_error' );
        this.max_error = max_error ? max_error : `The maximum value is {max}`;

        this.step = parseInt( this.$input.attr( 'step' ) );
        this.value = parseInt( this.$input.val() );
        let first_step = this.$input.attr( 'data-first_step' )
        if ( first_step ) {
            this.first_step = parseInt( first_step );
        }

        let object_id = this.$input.attr( 'data-object_id' );
        this.object_id = object_id ? object_id : false;

        let no_error = this.$input.attr( 'data-no_error' );
        this.show_errors = ! Boolean( no_error );

        this.$minus.click( () => {
            let step = ( this.first_step && this.value == this.first_step ) ? this.first_step : this.step;
            let new_val = this.value - step;
            let above_previous_step = new_val % step;
            if ( above_previous_step ) {
                new_val += step - above_previous_step;
            }
            if ( this.min !== false ) {
                new_val = Math.max( new_val, this.min );
            }
            this.update_value( new_val );
        });

        this.$plus.click( () => {
            let step = ( this.first_step && this.value == 0 ) ? this.first_step : this.step;
            let new_val = this.value + step;
            new_val -= new_val % step;
            if ( this.max !== false ) {
                new_val = Math.min( new_val, this.max );
            }
            this.update_value( new_val );
        });

        this.$input.change( () => {
            let value = this.$input.val();
            value = value ? value : 0;
            this.value = parseInt( value );
            this.trigger_min_max_error();
            this.value = ( this.first_step && this.value < this.first_step ) ? 0 : this.value;
            this.toggle_disabled();
            this.$input.val( this.value ).trigger( 'value_changed', this );
        });

        this.toggle_disabled();
        this.$element.addClass( 'initialized' );
    }

    update_value ( new_val ) {
        new_val = parseInt( new_val );
        if ( !isNaN( new_val ) && new_val != this.value ) {
            this.$input.val( new_val ).change();
        }
    }

    show_error_message ( message, args = false ) {
        if ( args ) {
            message = this.constructor.string_format( message, args );
        }
        this.$input.attr( 'title', message );
        this.$input.tooltip({
            trigger: 'manual',
            template: '<div class="tooltip number-input-tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
        });
        this.$input.tooltip( 'show' );
        setTimeout( () => {
            this.$input.tooltip( 'hide' );
        }, 3000 );
    }

    trigger_min_max_error () {
        let error = false;
        if ( this.min !== false && this.value < this.min ) {
            this.value = this.min;
            error = {
                message: this.min_error,
                args: {
                    min: this.min
                }
            };
        } else if ( this.max !== false && this.value > this.max ) {
            this.value = this.max;
            error = {
                message: this.max_error,
                args: {
                    max: this.max
                }
            };
        }

        if ( error ) {
            if ( this.show_errors ) {
                this.show_error_message( this.min_error, { min : this.min });
            } else {
                // allows use with external systems (JS_Render_Router)
                jQuery( document ).trigger( 'number_input_error', {
                    instance: this,
                    error: error
                });
            }
        }
    }

    toggle_disabled () {
        let hit_min = ( this.min !== false && ( this.value - this.step ) < this.min );
        let hit_max = ( this.max !== false && ( this.value + this.step ) > this.max );
        this.$minus.attr( 'disabled', hit_min );
        this.$plus.attr( 'disabled', hit_max );
    }

    // static props/methods

    static init () {
        let _class = this;
        window.Number_Input_Instances = {};
        jQuery( '.number-input:not(.initialized)' ).each( function () {
            let instance = new _class( jQuery( this ) );
            if ( instance.object_id ) {
                window.Number_Input_Instances[ instance.object_id ] = instance;
            }
        });
    }

    static string_format ( string, args ) {
        for ( var arg in args ) {
            string = string.replace( `{${ arg }}`, args[ arg ] );
        }
        return string;
    }

}