jQuery( function ($) {

    let $modal = $( '#menu-block-img-modal' );
    if ( !$modal.length ) { return; }

    let $moday_body = $( '.modal-body', $modal );

    $( '.menu-blocks-wrp' ).on( 'click', '.image-modal-trigger', function ( e ) {
        $moday_body.html( $(this).html() );
        $modal.modal( 'show' );
    });

});