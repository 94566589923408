/*--------------------------------------------*\
    Include Globally Useful JS Functions
\*--------------------------------------------*/

// 1-line jQuery plugin!
jQuery.fn.reverse = [].reverse;

ThemeJS.Functions = (function ( $, root ) {

    function send_ajax_request ( args ) {
        let $loading_screen = false;
        args.data.nonce = ThemeJS.Variables.Ajax.nonce;
        if ( typeof args.loading_screen != 'undefined' ) {
            $loading_screen = ( args.loading_screen instanceof jQuery ) ? args.loading_screen : $( args.loading_screen );
        }
        if ( $loading_screen && $loading_screen.length ) {
            $loading_screen.loading_screen();
        }
        $.post( ThemeJS.Variables.Ajax.url, args.data, function ( response ) {
            response = JSON.parse( response );
            if ( !response.hasOwnProperty( 'data' ) ) { response.data = {}; }
            if ( typeof args.complete == 'function' ) {
                args.complete( response.data );
            }
            if ( response.success && typeof args.success == 'function' ) {
                args.success( response.data );
            } else if ( !response.success && typeof args.error == 'function' ) {
                args.error( response.data );
            }
            if ( $loading_screen && $loading_screen.length ) {
                $loading_screen.loading_screen( false );
            }
        });
    }


    function wp_rest_request ( args ) {
		let ajax_args = {
			method: ( args.method ?? 'GET' ).toString().toUpperCase(),
			url: `${ ThemeJS.Variables.WP_REST.root }${ args.endpoint }`,
			data: args.data,
			contentType: 'application/json; charset=utf-8',
			beforeSend ( xhr ) {
				xhr.setRequestHeader( 'X-WP-Nonce', ThemeJS.Variables.WP_REST.nonce );
			},
			success ( data ) {
				if ( typeof args.success == 'function' ) {
					args.success( data );
				}
			},
			error ( data ) {
				if ( typeof args.error == 'function' ) {
					args.error( data );
				}
			},
			complete ( data ) {
				if ( typeof args.complete == 'function' ) {
					args.complete( data );
			}
			}
		};
		if ( args.jsonp ) {
			ajax_args.dataType = 'jsonp';
			ajax_args.jsonp = '_jsonp';
			if ( args.jsonpCallback ?? null ) {
				ajax_args.jsonpCallback = args.jsonpCallback;
			}
			if ( args.cache ?? null ) {
				ajax_args.cache = args.cache;
			}
		}
		if ( ajax_args.method != 'GET' ) {
			ajax_args.dataType = 'json';
			ajax_args.data = JSON.stringify( ajax_args.data );
		}
		jQuery.ajax( ajax_args );
	}


    function get_query_parameter( name, url ) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&#]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    function get_property ( object, path, default_val ) {
        let value = jQuery.extend( true, {}, object );
        jQuery.each( path.split('.'), function ( level, level_name ) {
            value = value[ level_name ];
            if ( typeof value == 'undefined' ) {
                return false;
            }
        });
        return ( typeof value == 'undefined' ) ? default_val : value;
    }

    function set_property ( obj, path, val ) {
        const keys = path.split('.');
        const lastKey = keys.pop();
        const lastObj = keys.reduce((obj, key) => 
            obj[key] = obj[key] || {}, 
            obj); 
        lastObj[lastKey] = val;
    }

    function generate_random ( length ) {
        if ( typeof length == 'undefined' ) {
            length = 10;
        }
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var characters_length = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * characters_length));
        }
        return result;
    }

    function init_product_description_tooltips () {
        $('.product-description[data-toggle=tooltip]').tooltip({
            html: true,
            template: '<div class="tooltip product-description-tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
        });
    }

    $( function () {

        init_product_description_tooltips();
        $( document.body ).on( 'updated_checkout', init_product_description_tooltips );

        $( 'select.select2' ).select2({
            minimumResultsForSearch: -1
        });

        // smooth-scroll / toggle modal on click of id href
        $( document ).on( 'click', 'a[href^="#"]', function ( e ) {
            let $target = $( $(this).attr('href') );
            if ( $target.length ) {
                e.preventDefault();
                if ( $target.hasClass('modal') ) {
                    $target.modal('toggle');
                    return;
                }
                let options = { scrollTop: $target.offset().top };
                $(window).trigger( 'autoscrolling', options );
                $('html, body').animate(
                    options,
                    1000,
                    // function() {
                    //     $target.focus();
                    //     if ( $target.is(":focus") ) {
                    //         return false;
                    //     } else {
                    //         $target.attr('tabindex','-1');
                    //         $target.focus();
                    //     }
                    // }
                );
            }
        });
    });
    
    return {
        send_ajax_request,
        wp_rest_request,
        get_query_parameter,
        get_property,
        set_property,
        generate_random,
        init_product_description_tooltips
    }

})( jQuery, this );