(function ( $, root ) {

    $( function () {
        $( document ).on( 'change', '.ginput_container_fileupload input[type=file]', function () {
            if ( this.files.length ) {
                $( '.filename', $(this).closest( '.gfield' ) ).html( this.files[0].name ).removeClass('placeholder');
            }
            let $errors = $( '.validation_message', $(this).closest('.gfield') );
            if ( $errors.length ) {
                $errors.remove();
            }
        });
    })

})( jQuery, this );