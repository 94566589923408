jQuery( function ( $ ) {

    if ( typeof window.srQueue == 'undefined' ) { return; }

    let queue_display_initialized = false;
    let $queue_display = $( '.queue-display' );
    let $queue_position = $( '.queue-position', $queue_display );
    let $wait_time = $( '.queue-wait-time', $queue_display );
    let $time_remaining = $( '.queue-time-remaining', $queue_display );

    function append_queue_terms_element () {
        if ( !$('#queue-terms' ).length ) {
            $( 'footer .footer-bottom > .container-large' ).append( $('<div>').attr( 'id', 'queue-terms' ) );
        }
    }

    window.startQueue = function () {
        append_queue_terms_element();
        set_queue_activated_cookie( false );
        window.srQueue.start( 'queue-terms' );
    }

    window.restartQueue = function () {
        append_queue_terms_element();
        set_queue_activated_cookie( false );
        window.srQueue.restart( 'queue-terms' );
    }

    function init_queue_display () {
        if ( queue_display_initialized ) { return; }
        queue_display_initialized = true;
        set_queue_cookie();
        $queue_display.slideDown( 300 );
    }

    function set_queue_cookie ( set = true ) {
        ThemeJS.Cookies.set({ sanremo_queue_session: set ? window.srQueue.session() : null });
    }

    function set_queue_activated_cookie ( set = true ) {
        ThemeJS.Cookies.set({ sanremo_queue_activated: set ? 'yes' : null });
    }
    

    window.addEventListener('srQueue_time_remaining_update', function (e) {
        init_queue_display( true );
        if ( $time_remaining.length ) {
            $queue_display.addClass( 'activated' );
            let time = `${ e.detail.seconds } Seconds`;
            if ( e.detail.minutes ) {
                time = `${ e.detail.minutes } Minute${ e.detail.minutes > 1 ? 's' : '' } ${ time }`;
            }
            if ( e.detail.hours ) {
                time = `${ e.detail.hours } Hour${ e.detail.hours > 1 ? 's' : '' } ${ time }`;
            }
            $time_remaining.html( time );
        }
    });

    window.addEventListener('srQueue_estimated_wait_update', function (e) {
        init_queue_display();
        if ( $wait_time.length ) {
            let time = `${ e.detail.minutes } Minutes`;
            if ( e.detail.hours ) {
                time = `${ e.detail.hours } Hour${ e.detail.hours > 1 ? 's' : '' } ${ time }`;
            }
            $wait_time.html( time );
        }
        $(window).trigger( 'queue_status_update', 'waiting' );
    });
    
    window.addEventListener('srQueue_position_update', function (e) {
        init_queue_display();
        if ( $queue_position.length ) {
            $queue_position.html( e.detail );
        }
    });

    window.addEventListener('srQueue_activated', function (e) {
        if ( !ThemeJS.Cookies.get( 'sanremo_queue_activated' ) ) {
            set_queue_activated_cookie();
            $(window).trigger( 'sanremo_queue_first_activation' );
        }
    });

    $(window).on('sanremo_queue_first_activation', function (e) {
        var text = "You've been granted access to the shop for 20min!";
        var notification = new Notification( 'Shop Activated!', {
            body: text,
            vibrate: [ 200, 200 ],
        });
    });

    window.addEventListener('srQueue_expired', function (e) {
        window.location.href = Sanremo_Queue.calendar_url;
    });

    // window.addEventListener('srQueue_disconnected', function (e) {
    //     console.log('srQueue_disconnected');
    // });

    // start the queue if already have cookie set
    let session = ThemeJS.Cookies.get( 'queue' );
    let integrity = ThemeJS.Cookies.get( 'queue_integrity' );
    if ( session && integrity ) {
        window.startQueue();
    } else {
        window.srQueue.clear_session();
    }

});