
// Space left above content to properly space fixed header.
(function ( $, root ) {

    let $header;
    let $spacer;
    let $mobile_menu;

    function recalc_height(){
        let menu_height = $mobile_menu.is(':visible') ? $mobile_menu.height : 0;
        let height = $header.hasClass( 'opaque' ) ? $header.height() - menu_height : 0;
        $spacer.height( height );
    }

    $( document ).ready( function(){
        $header = $( 'header' );
        $spacer = $( '.head-spacer' );
        $mobile_menu = $( '.mobile-menu' );
        $header.css({ position: 'fixed' });
        if( ! $spacer.length ){
            return;
        }
        recalc_height();
        $( window ).on( 'scroll resize', recalc_height );
    });

    $(window).on( 'autoscrolling', function ( e, options ) {
        options.scrollTop -= $spacer.height();
    });

})( jQuery, this );