(function ( $, root ) {

    let options = {
        infinite:false,
        dots:false,
        arrows:true,
        autoplay:false,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><i class="fas fa-arrow-left"></i></button>',
        nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"><i class="fas fa-arrow-right"></i></button>',
        responsive: [
            // {
            //     breakpoint: 991,
            //     settings: {
            //         slidesToShow: 2,
            //         slidesToScroll: 2
            //     }
            // },
            // {
            //     breakpoint: 550,
            //     settings: {
            //         slidesToShow: 1,
            //         slidesToScroll: 1
            //     }
            // }
        ]
    };

    $( '.text-and-slider' ).each( function () {
        $( '.slider-container' ).slick( Object.assign( options, {
            prevArrow: $( '.prev-arrow', this ),
            nextArrow: $( '.next-arrow', this ),
        }));
    });

})( jQuery, this );