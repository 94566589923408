ThemeJS.Sidebar_Scroller = (function($) {
    var canHover = !matchMedia("(hover: none)").matches;
    if (canHover) {
        document.body.classList.add("can-hover");
    }
  
    let $target_sections;
    let $sidebar_links;
  
    $(function() {
        $target_sections = $(".sidebar-main .main-content .scroll-target");
        if (!$target_sections.length) {
            return;
        }
        $sidebar_links = $(".sidebar-main .sidebar a");
        if (!matchMedia("(hover: none)").matches) {
            $sidebar_links.addClass("can-hover");
        }
        $(window).scroll(activate_current_section);
        $(window).trigger("scroll");
    });
  
    function activate_current_section() {
        let active_set = false;
    
        let window_top = $(window).scrollTop();
        let window_bottom = window_top + $(window).height();
    
        // build object of top/bottom values for each section
        // set active if section is fully visible
        let sections = {};
        $target_sections.each(function() {
            let this_offset = $(this).offset();
            let this_bottom = this_offset.top + $(this).height();
            sections[this.id] = {
            top: this_offset.top,
            bottom: this_bottom
            };
            if (
            sections[this.id].top >= window_top &&
            sections[this.id].bottom <= window_bottom
            ) {
            activate_link(this.id);
            active_set = true;
            return false;
            }
        });
        if (active_set) {
            return;
        }
            // activate last section started above screen top
        $target_sections.reverse().each(function() {
            if (
                sections[this.id].top <= window_top + 100 &&
                sections[this.id].bottom >= window_top
            ) {
                activate_link(this.id);
                active_set = true;
                return false;
            }
        });
        // $target_sections reversed by reference in previous loop, so we must return to proper order
        $target_sections.reverse();
        if (active_set) {
            return;
        }
        activate_link($target_sections[0].id);
    }
  
    function activate_link(id) {
        $sidebar_links.removeClass("current_page_item");
        $sidebar_links.filter(`[href='#${id}']`).addClass("current_page_item");
        $(document).trigger( 'scrolling-sidebar-link-activated', id );
    }
})(jQuery);
  